import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TitleBox from "../components/titleBox"
import { FaFilePdf } from "react-icons/fa"
import Img from "gatsby-image"
import RegLink from "../components/regLink"
import BlockContentPrimary from "../components/BlockContentPrimary"
import SEO from "../components/SEO"

const Registration = ({ data }) => {
  const { acrobat, registration, planners } = data

  const { _rawDescription: rawDescription } = registration

  const fluid = registration?.headerImage?.asset?.fluid || null

  const acrobatImg = acrobat.childImageSharp.fixed

  const regFormUrl = data.regFormDownload.fileUpload
    ? data.regFormDownload.fileUpload.asset.url
    : null

  return (
    <Layout>
      <SEO title="Registration" />
      <TitleBox title="Registration" className="flex-grow" background={fluid}>
        {/* Contents of Title Box are inserted into component as {children} */}
        <div className="registration__details">
          <div className="pageContent">
            <BlockContentPrimary blockData={rawDescription} />{" "}
          </div>

          {/* Temporarily disabled this with a conditional statement */}
          {null && (
            <div className="flex flex-col items-center row-gap-8  md:flex-row md:col-gap-10 md:justify-center  md:h-32 my-8">
              <RegLink url="https://ti.to/nofnec/nofnec-2020">
                Register Online
              </RegLink>

              {regFormUrl ? (
                <>
                  <div className="registration__separator hidden md:block" />

                  {/* See Sanity instructions for file downloads https://www.sanity.io/docs/file-type#download-file-56c56d68424b  */}

                  <RegLink
                    className="registration__download gap-2"
                    url={`${regFormUrl}?dl=`}
                  >
                    or Download Form
                    <div>
                      <Img fixed={acrobatImg} />
                    </div>
                  </RegLink>
                </>
              ) : null}
            </div>
          )}
        </div>
      </TitleBox>
      {null && (
        <div className="registration__moreInfo">
          <div className="registration__contactInfo">
            <h1 className="registration__contactInfo-title">
              Contact for More Info
            </h1>
            <div className="registration__verticalDivider"></div>
            <div className="registration__contactInfo-details">
              <p>
                <span className="registration__contactName">
                  {planners.primaryContactName}
                </span>
                ,
                <br />
                {planners.primaryContactTitle}
                <br />
                {planners.orgInfo.orgName}
                <br />
                {planners.contactInfo.mailingAddress.address1}
                <br />
                {planners.contactInfo.mailingAddress.address2}
              </p>
              <p>Tel: {planners.contactInfo.phone}</p>
              <p>
                Email:{" "}
                <a href={`mailto:${planners.contactInfo.email}?subject=NOFNEC`}>
                  {planners.contactInfo.email}
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  query RegistrationQuery {
    acrobat: file(relativePath: { eq: "acrobat.png" }) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    registrationForm: file(relativePath: { eq: "RegistrationForm.pdf" }) {
      publicURL
    }
    planners: sanityPlanners {
      orgInfo {
        orgName
        url
      }
      contactInfo {
        email
        phone
        fax
        mailingAddress {
          address1
          address2
          city
          country
          postalCode
          provinceState
        }
      }
      primaryContactName
      primaryContactTitle
    }
    regFormDownload: sanityRegistration {
      fileUpload {
        asset {
          url
        }
      }
    }
    registration: sanityRegistration {
      fileUpload {
        asset {
          url
        }
      }
      _rawDescription
      headerImage {
        asset {
          fluid(maxWidth: 1800) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

export default Registration
